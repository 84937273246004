.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 488px;
}

.form-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-width: Rem(488px);
  margin: 0 auto;
  width: 90%;
  @include respond-to(desktop-up) {
    width: 100%;
  }
}
