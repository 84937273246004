@mixin buttonHoverTransition {
  transition: all 0.3s ease-in-out;
  transform: scale(1);
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-out;
  }
}

@mixin tealOverlay {
  background: rgba(12, 148, 105, 0.4) !important;
  mix-blend-mode: overlay;
}
