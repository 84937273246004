/********************************************
        MIXINS
********************************************/

@mixin transition01 {
  transition: all 0.1s;
}
@mixin transition02 {
  transition: all 0.2s;
}
@mixin transition03 {
  transition: all 0.3s;
}

@mixin transition04 {
  transition: all 0.4s;
}
@mixin transition05 {
  transition: all 0.5s;
}
@mixin transition06 {
  transition: all 0.6s;
}

@mixin transition07 {
  transition: all 0.7s;
}
@mixin transition08 {
  transition: all 0.8s;
}

@mixin hide {
  @include transition02;
  opacity: 0;
  visibility: hidden;
}
@mixin show {
  @include transition02;
  opacity: 1;
  visibility: visible;
}

@mixin easeIn {
  transition-timing-function: ease-in;
}
@mixin easeOut {
  transition-timing-function: ease-out;
}
@mixin easeInOut {
  transition-timing-function: ease-in-out;
}

/********************************************
        CLASSES
********************************************/
.show {
  @include show;
}
.hide {
  @include hide;
}

/********************************************
        BASE EM CALC
********************************************/

@function em($px, $base: 16) {
  @return ($px / $base) * 1em;
}

@function Rem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}
