.want-more-callout {
  @include transition02;
  @include easeOut;
  height: Rem(72px);
  bottom: Rem(-20px);
  display: flex;
  align-items: center;

  box-sizing: border-box;
  padding: Rem(30px) Rem(80px) Rem(30px) Rem(40px);
  position: absolute;
  right: 0;
  text-decoration: none;
  width: 100%;
  z-index: 10;

  &:before {
    background: rgba(8, 11, 39, 1);

    mix-blend-mode: multiply;
    border-radius: Rem(20px);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &:after {
    background: url("../../images/book_cutout.png") no-repeat center center;
    background-size: Rem(100px);
    height: Rem(124px);
    width: Rem(100px);
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scale(1) rotate(0);
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    @include transition01;
    @include easeOut;
    height: Rem(180px);
    &:after {
      animation: shake 1s ease-in-out forwards;
    }
  }
  .text {
    font-size: Rem(12px);
    font-weight: $semi-bold;
    display: block;
    line-height: Rem(15px);
    max-width: 80%;
    z-index: 1;
  }
  @include respond-to(tablet-up) {
    bottom: Rem(-65px);
    display: block;
    height: Rem(150px);
    position: fixed;
    width: Rem(392px);
    &:after {
      background-size: 140px;
      right: Rem(13px);
      bottom: Rem(60px);
      height: Rem(145px);
      width: Rem(140px);
    }
    &:before {
      border-radius: Rem(40px);
      border-top-right-radius: 0;
      border-bottom-right-radius: auto;
      border-bottom-left-radius: auto;
      background: linear-gradient(
        88.12deg,
        rgba(115, 137, 156, 0.2) 0.16%,
        rgba(0, 0, 0, 0.3) 97.38%
      );
      mix-blend-mode: overlay;
    }
  }
}

.want-more-callout--mobile {
  position: absolute;
  bottom: 0;
  @include respond-to(tablet-up) {
    bottom: Rem(-70px);
  }
}

.want-more-callout.hide {
  @include easeOut;
  transform: translateX(100px);
}

.want-more-callout.show {
  @include easeIn;
  transform: translateX(0);
}
