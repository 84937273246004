@keyframes ShapeDistort {
  0% {
    transform: skewX(100deg);
  }
  50% {
    transform: skewX(20deg);
  }

  100% {
    transform: skewX(50deg);
  }
}

.mouse-follower {
  &.hide {
    @include transition06;
    opacity: 0;
    visibility: hidden;
  }
  &.show {
    @include transition06;
    opacity: 1;
    visibility: visible;
  }
}

.content {
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  isolation: isolate;
  z-index: 1;
}

.content__img {
  max-width: var(--img-maxwidth);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  will-change: transform;
}

.content__img--full {
  width: 100%;
  height: 100%;
  background-size: cover;
}
