@mixin basekick($fontSize, $lineHeight) {
  transform: translateY(0);
  &:before {
    box-sizing: border-box;
    content: "";
    display: block;
    height: 0;
    margin-top: $fontSize;
    width: 100%;
  }

  &:after {
    content: "";
    display: block;
    height: 0;
    margin-top: $lineHeight;
  }
}
