.primary-btn {
  @include transition04;
  align-items: center;

  background: rgba(255, 255, 255, 0.1);
  border: Rem(2px) solid $white;
  border-radius: Rem(50px);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: Rem(40px);
  padding: 0 Rem(22px);
  justify-content: center;
  text-decoration: none;
  @include respond-to(tablet-up) {
    height: Rem(50px);
    padding: 0 Rem(40px);
  }
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: auto;
  }

  &:hover {
    border-color: transparent;
  }
  &:enabled {
    &:hover {
      @include transition03;
      background: $white;
      color: $teal;
      outline-color: $white;
    }
  }
  &:disabled {
    border: none;
    cursor: default;
    opacity: 0.4;
  }
  &.dark-mode {
    @include transition04;
    background: linear-gradient(
      88.12deg,
      rgb(39, 43, 64) 6.16%,
      rgba(15, 18, 44, 1) 97.38%
    );
  }
}

// Modifiers

.navigation-cta {
  position: relative;
  margin-top: 0;
  width: auto;
}
