.spacer {
  display: flex;
  &.desktop-only {
    display: none;
    visibility: hidden;
    @include respond-to(desktop-up) {
      display: block;
      visibility: visible;
    }
  }
  &.device-only {
    display: block;
    visibility: visible;
    @include respond-to(desktop-up) {
      display: none;
      visibility: hidden;
    }
  }
}

.spacer-layout__phone--col {
  display: inline-flex;
}

.spacer-layout__phone--row {
  display: flex;
}

@include respond-to(desktop-up) {
  .spacer-layout__desktop--col {
    display: inline-flex;
  }

  .spacer-layout__desktop--row {
    display: flex;
  }
}

@mixin flexWidths($size) {
  max-width: $size;
  min-width: $size;
  width: $size;
}

@mixin size($size) {
  height: $size;
  &--inline {
    @include flexWidths($size);
  }
}

@mixin allSizes {
  &-xn {
    @include size(6px);
  }
  &-n {
    @include size(12px);
  }
  &-xxs {
    @include size(18px);
  }
  &-xs {
    @include size(24px);
  }
  &-s {
    @include size(36px);
  }
  &-m {
    @include size(48px);
  }
  &-xm {
    @include size(60px);
  }
  &-l {
    @include size(72px);
  }
  &-xl {
    @include size(84px);
  }
  &-xxl {
    @include size(96px);
  }
  &-xxxl {
    @include size(120px);
  }
  &-g {
    @include size(144px);
  }
}

.spacer-size--phone {
  @include allSizes;
}

@include respond-to(tablet-up) {
  .spacer-size--tablet {
    @include allSizes;
  }
}

@include respond-to(desktop-up) {
  .spacer-size--desktop {
    @include allSizes;
  }
}

@include respond-to(widescreen-up) {
  .spacer-size--widescreen {
    @include allSizes;
  }
}
