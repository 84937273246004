.embedsocial-story-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  position: abs($number: 0);

  @include respond-to(desktop-up) {
    width: 100%;
  }
}

.show-feed {
  z-index: 12 !important;
  position: absolute;
}

.hide-feed {
  z-index: -1 !important;
}

.embedsocial-story-temp {
  height: 100vh;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 27vh;
  z-index: 1000;
  @include respond-to(desktop-up) {
    margin-left: 50vw;
    width: 34vh;
  }
}
