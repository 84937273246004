.header {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  left: 0;
  margin: 0 auto;
  padding: Rem(28px);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  @include respond-to(desktop-up) {
    height: Rem(100px);
    transform: translateY(50px);
    max-width: calc(100% - 120px);
    padding: 0;
  }
  &.scrolled {
    @include transition02;
    @include easeIn;
    transform: translateY(0);

    @include respond-to(desktop-up) {
      transform: translateY(20px);
      .logo {
        transform: scale(0.6);
      }
    }
  }
  &.default {
    @include transition02;
    @include easeIn;
  }
}

.header__content {
  align-items: flex-start;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
