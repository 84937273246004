.input {
  background: none !important;
  background-color: transparent !important;
  font-size: Rem(20px);
  font-family: "OpenSans", sans-serif;
  height: Rem(20px);
  width: 100%;
  display: flex;
  border: none;
  padding-left: Rem(20px);
  border-bottom: 1px solid $white;
  @include respond-to(small-phone-up) {
    height: Rem(50px);
  }
  &:focus {
    @include tealOverlay;
    outline: none;
  }
}

.agree-checkbox {
  display: flex;
  input[type="checkbox"] {
    background-attachment: none;
    border: Rem(2px) solid $white;
    cursor: pointer;
    flex: 1;
    height: Rem(20px);
    max-width: Rem(20px);
    opacity: 0;
    width: Rem(20px);
    z-index: 1;
  }
  label {
    flex: 1;
    font-size: Rem(14px);
    padding-left: Rem(40px);
    position: absolute;
    &:before {
      border: Rem(2px) solid $white;
      content: "";
      height: Rem(15px);
      max-width: Rem(15px);
      width: Rem(20px);
      position: absolute;
      left: 0;

      z-index: 0;
    }
  }

  input[type="checkbox"]:focus + label:before {
    @include tealOverlay;
  }
  input[type="checkbox"]:checked + label:before {
    @include transition06;
    @include easeOut;
    background: $black !important;
    transform: rotate(360deg);
  }
}
