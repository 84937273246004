.background {
  @include transition06;
  will-change: auto;
  background: url("../../images/gradient-rect.svg");
  background-size: cover;
  transform: scale(2);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-position: center center;
}

@-moz-document url-prefix() {
  .background {
    background: none;
  }
}

.background__about {
  background: $navy;
  opacity: 0;
  &--entered {
    @include transition06;
    opacity: 1;
  }
  &--exited {
    @include transition06;
    opacity: 0;
  }
}
