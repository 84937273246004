.full-width {
  width: 100%;
}

.hidden {
  z-index: -2 !important;
  visibility: hidden;
  opacity: 0;
  @include transition05;
}

.visibile {
  z-index: 0 !important;
  visibility: visible;
  opacity: 1;
  @include transition05;
}

.locked {
  overflow: hidden;
}
