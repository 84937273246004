.about__tagline {
  display: block;
}

.landing__section--about {
  padding-bottom: Rem(100px);
  position: relative;
  display: block;
  z-index: 2;
  width: 100%;
  @include respond-to(tablet-up) {
    padding-bottom: 0;
  }
}
