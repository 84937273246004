//  Base
@import "./styles/base/basekick";
@import "./styles/base/breakpoints";
@import "./styles/base/helpers";
@import "./styles/base/theme";
@import "./styles/base/typography";
@import "./styles/base/utils";

// Mixins
@import "./styles/mixins";

// Buttons
@import "./styles/buttons/primary";
@import "./styles/buttons/instagram_cta";

// Elements
@import "./styles/elements/spacer";

// Forms
@import "./styles/form/index";
@import "./styles/form/input";

// Ui
@import "./styles/ui/challenge";
@import "./styles/ui/header";
@import "./styles/ui/logo";
@import "./styles/ui/modal";
@import "./styles/ui/navigation";
@import "./styles/ui/social_icon_group";
@import "./styles/ui/swiper";
@import "./styles/ui/want_more_callout";

// Animations & FX
@import "./styles/animation";
@import "./styles/effects/background";
@import "./styles/effects/mouse_follower";

@import "./styles/instagram_feed";
@import "./styles/landing";
@import "./styles/layout";
@import "./styles/sections";

// Pages
@import "./styles/pages/about";

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body {
  background-color: #23d49c;
  background-size: 800% 800%;
  height: 100vh;
  font-size: 16px;
  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

html {
  cursor: url("./images/cursor.svg"), auto;
}

.App {
  text-align: center;
  position: relative;
  z-index: 1;
}
input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
