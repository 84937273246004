.logo {
  @include transition02;
  @include easeIn;
  display: block;
  height: Rem(50px);
  transform: scale(1);
  position: relative;
  width: Rem(50px);
  z-index: 100;

  @include respond-to(desktop-up) {
    height: Rem(92px);
    width: Rem(92px);
  }

  .svg {
    @include transition04;
    height: Rem(50px);
    top: 0;
    left: 0;
    position: absolute;
    width: Rem(50px);
    transform: scale(1);
    &:hover {
      @include transition04;
      transform: scale(1.1);
    }

    @include respond-to(desktop-up) {
      height: Rem(92px);
      width: Rem(92px);
    }
  }

  .svg--show {
    @include transition02;
    opacity: 1;
  }
  .svg--swap {
    @include transition02;
    opacity: 0;
  }
}
