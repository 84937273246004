@keyframes InOut {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.challenge {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  &.homepage {
    margin-top: 30vh;
    padding: 0 Rem(20px);
    @include respond-to(desktop-up) {
      margin-top: 12vh;
      padding: 0;
    }
    .challenge-content {
      text-align: left;
    }
    .challenge-question__welcome-text {
      margin: 0;
      position: relative;
      text-align: left;
      top: 0;
    }
  }
  @include respond-to(tablet-up) {
    align-items: flex-start;
    margin-top: 0;
  }
}

.challenge-question__welcome-text {
  position: absolute;
  top: 15vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;

  max-width: Rem(180px);
  @include respond-to(desktop-up) {
    max-width: 100%;
    position: relative;
    top: 0;
    text-align: left;
    margin: 0;
  }
}

.next-challenge {
  position: absolute;
  right: Rem(220px);
}

.challenge-timer {
  display: flex;
  flex-direction: column;
  width: Rem(150px);
}
.challenge-timer-items {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.challenge-timer-item {
  flex: 1;
  margin-right: Rem(10px);
  min-width: 50%;
  width: 50%;
}

.challenge-content {
  text-align: center;

  @include respond-to(desktop-up) {
    text-align: left;
  }
}
.challenge-question {
  flex: 1;
  max-width: 100%;
  transform: translateY(-11vh);
  width: 100%;
  @include respond-to(desktop-up) {
    max-width: 60vw;
    transform: translateY(0);
  }

  @include respond-to(widescreen-up) {
    max-width: Rem(1000px);
    min-width: Rem(900px);
  }
  .text {
    @include respond-to(desktop-up) {
      padding-right: Rem(100px);
    }
  }
}

.challenge__starting-in {
  animation: InOut 1.4s infinite ease-in-out;
}
.challenge-question--gallery {
  @include respond-to(desktop-up) {
    flex: 1;
    max-width: 50%;
    min-width: 50%;
    // margin-top: 17vh;
    padding-left: Rem(130px);
    width: 50%;
  }
  @include respond-to(widescreen-up) {
    flex: 1;
    max-width: 33%;
    min-width: 3%;
    width: 33%;
  }
}
