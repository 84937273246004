div[data-reach-dialog-overlay] {
  background: none;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.modal[data-reach-dialog-content] {
  align-items: center;
  background: none;
  display: flex;
  height: auto;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: scroll;
  @include respond-to(desktop-up) {
    height: 100%;
    overflow: hidden;
  }
}

.modal-button--close {
  background: none;
  border: none;
  position: fixed;
  top: Rem(20px);
  right: Rem(20px);
  z-index: 100;
  @include respond-to(desktop-up) {
    top: Rem(60px);
    right: Rem(60px);
  }
}

.modal-content.hide {
  transform: translateX(100px);
}

.modal-content.show {
  transform: translateX(0);
}

.modal-content {
  height: 100vh;
  margin-top: Rem(100px);
  overflow: scroll;
  @include respond-to(desktop-up) {
    height: auto;
    margin-top: 0;
    overflow: hidden;
  }
}
div[data-reach-dialog-content] {
  background: none;
}
