.social-icon-group {
  align-items: center;
  display: flex;
  list-style: none;
  padding-left: 0;
  position: relative;
  justify-content: space-between;
  transform: translateX(-10px);
  width: Rem(100px);
  @include respond-to(desktop-up) {
    margin-left: Rem(33px);
    top: 0;
    transform: translateX(0);
    right: 0;
  }
}

@mixin icon_background {
  background-repeat: no-repeat;
  background-size: Rem(16px);
  background-position: center center;
}

.social-icon {
  @include transition02;
  border-bottom: Rem(2px) solid transparent;
  flex: 1;
  height: Rem(39px);
  position: relative;
  width: Rem(16px);
  span {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
  &--linkedin {
    background: url("../../images/icons/linkedin.svg");
    @include icon_background;
  }
  &--facebook {
    background: url("../../images/icons/facebook.svg");
    @include icon_background;
    background-size: Rem(8px);
  }
  &--instagram {
    background: url("../../images/icons/instagram.svg");
    @include icon_background;
  }

  &:hover {
    @include transition01;
    border-bottom-color: $white;
  }
}
