@mixin arrow-btns {
  align-items: center;
  border: Rem(2px) solid $white !important;
  background: rgba(255, 255, 255, 0.32) !important;
  border-radius: 100% !important;
  display: flex !important;
  justify-content: center;
  height: Rem(40px) !important;
  width: Rem(40px) !important;
  z-index: 20 !important;
}

.swiper-container {
  height: auto;
  width: 100%;
  @include respond-to(desktop-up) {
  }
}
.swiper-wrapper {
  width: calc(100% - 250px);
  overflow: visible;
  align-items: center;
  margin: 0 auto;
  height: 100vh !important;
}
.swiper-slide {
  align-items: flex-end;
  display: flex;
  padding: Rem(20px);

  @include respond-to(desktop-up) {
    align-items: center;
    padding: 0;
  }
}

.swiper-navigation {
  position: relative;
  top: 0;
  display: block;
}
.swiper-button-prev {
  @include arrow-btns;
  // position: absolute;
  // top: 50vh !important;
  // z-index: 30 !important;
  // left: Rem(45px) !important;
  &:after {
    background: url("../../images/chevron-left.svg") center right 50% no-repeat !important;
    background-size: 10px !important;
    content: "" !important;
    height: 100%;
    flex: 1;
    height: Rem(20px);
    position: relative;
    width: 100%;
  }
}
.swiper-button-next {
  @include arrow-btns;
  // position: absolute;
  // top: 50vh !important;
  // z-index: 30 !important;
  // right: Rem(45px) !important;
  &:after {
    background: url("../../images/chevron-right.svg") center left 50% no-repeat !important;
    background-size: 10px !important;
    content: "" !important;
    height: 100%;
    flex: 1;
    height: Rem(20px);
    position: relative;
    width: 100%;
  }
}
