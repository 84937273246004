.instagram-cta {
  align-items: center;
  background: linear-gradient(
    88.12deg,
    rgba(255, 255, 255, 0.25) 6.16%,
    rgba(255, 255, 255, 0.05) 97.38%
  );
  border-radius: 30px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 Rem(50px) 0 Rem(60px);
  position: relative;
  height: Rem(50px);
  text-decoration: none;
  &:before {
    background: url("/images/icons/instagram.svg") center center no-repeat;
    background-size: cover;
    content: "";
    height: Rem(20px);
    left: Rem(20px);
    top: Rem(16px);
    position: absolute;
    width: Rem(20px);
  }
}
