.layout {
  display: block;
  position: relative;
  top: 0;
}
.pages {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}
.page {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;
  z-index: 1;

  @include respond-to(desktop-up) {
    align-items: center;
    padding-bottom: 0;
    height: 100vh;
  }
}

.page-content {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  min-height: 70vh;
  margin: 0 auto;
  padding: Rem(28px);
  width: 100%;
  position: relative;
  @include respond-to(tablet-up) {
    margin-top: 0;
    padding: 5vw;
  }
  @include respond-to(widescreen-up) {
    padding: 0 15%;
  }
}

.content--slim {
  max-width: Rem(486px);
}

#about {
  .page-content {
    align-items: center;
    justify-content: center;
    height: auto;
    padding-top: 25vw;
    min-height: 100vh;

    @include respond-to(widescreen-up) {
      padding-top: 2vw;
    }
  }
}

#home {
  .page-content {
    padding-right: 0;
    padding-left: 2vw;
    @include respond-to(desktop-up) {
      padding-left: 5vw;
    }
  }
}

#privacy {
  position: absolute;
  top: 0;
  z-index: 200;
  background: $teal;
  width: 100%;
  height: auto;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  .page-content {
    height: auto;
    position: relative;
    @include respond-to(desktop-up) {
      max-width: Rem(800px);
      padding: Rem(100px);
    }
  }
  * {
    color: $navy;
  }
}
// mofifirs
.page.challenge {
  align-items: flex-start;
  justify-content: flex-start;

  .page-content {
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10vw;
  }
}

.page.gallery {
  max-width: 100%;
  width: 100%;
  .page-content {
    padding: 0;
    max-width: 100%;
  }
}
