.navigation {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  display: block;
  height: 100%;
  width: 100%;
  left: 0;
  padding: Rem(20px);
  position: fixed;
  top: 0;
  @include respond-to(desktop-up) {
    align-items: center;
    background-color: transparent !important;
    display: flex;
    height: auto;
    flex-direction: row;
    justify-content: flex-end;
    left: 0;
    top: 0;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: 0;
    visibility: visible;
  }
}

@mixin toggleBar {
  background: $white;
  border-radius: Rem(3px);
  height: Rem(3px);
  position: absolute;
  right: 0;
  width: Rem(33px);
}

.navigation-toggler {
  background: none;
  border: none;
  display: block;
  height: Rem(25px);
  position: fixed;
  visibility: visible;
  z-index: 20;
  top: Rem(25px);
  right: Rem(20px);
  width: Rem(30px);
  &:focus {
    outline: none;
  }
  &.on {
    @include transition01;
    @include easeOut;
  }

  &.off {
    @include easeIn;
    @include transition01;
    transform: rotate(0);
  }
}

.navigation-hamburger {
  @include toggleBar;
  @include easeIn;
  @include transition03;
  @include respond-to(desktop-up) {
    visibility: hidden;
  }
}

.navigation-hamburger--top {
  top: 0;
  transform-origin: top left;
  &.on {
    transform: rotate(45deg);
    opacity: 1;
  }
}
.navigation-hamburger--middle {
  top: calc(50% - 2px);
  &.hide {
    transform: rotate(90deg);
    opacity: 0;
  }
}
.navigation-hamburger--bottom {
  bottom: 0;
  transform-origin: bottom left;
  &.on {
    transform: rotate(-45deg);
    opacity: 1;
  }
}

.navigation__items {
  height: auto;
  align-items: flex-start;
  justify-content: center;

  display: flex;
  flex-direction: column;
  list-style: none;
  margin-right: 0;
  text-align: center;
  padding-left: 0;
  max-height: 50vh;
  // margin-top: 20vh;

  @include respond-to(desktop-up) {
    align-items: flex-end;
    flex-direction: row;
    height: auto;
    text-align: right;
    margin-top: 0;
    max-height: 100%;
  }
  @include respond-to(widescreen-up) {
    margin-right: Rem(10px);
  }
}

.navigation__item {
  @include transition02;
  align-items: center;
  display: flex;
  height: Rem(39px);
  flex: 1;
  margin-bottom: Rem(44px);
  a {
    text-decoration: none;
  }
  @include respond-to(desktop-up) {
    margin: 0 Rem(30px);
    margin-bottom: 0;
  }
}
.navigation__item-link {
  @include transition02;
  align-items: center;
  display: flex;
  border-bottom: 2px solid transparent;
  height: 100%;
  @include squad-heavy;
  font-size: Rem(54px);

  @include respond-to(desktop-up) {
    font-family: "Hind", sans-serif;
    @include caption1;
  }
  &:hover {
    @include transition02;
    border-bottom-color: $white;
  }
}

.navigation__item-link--active {
  @include respond-to(desktop-up) {
    @include transition02;
    border-bottom-color: $white;
  }
}

.navigation__logo {
  width: 100%;
}

.navigation.hide {
  @include easeOut;
  display: none;
  transform: translateY(-100px);
  @include respond-to(desktop-up) {
    display: flex;
  }
}

.navigation.show {
  background-color: $navy;
  height: 100vh;
  @include easeIn;
  display: flex;
  transform: translateY(0);
  @include respond-to(desktop-up) {
    height: auto;
  }
}
