* {
  color: $white;
  font-family: "Hind", sans-serif;
  margin-bottom: 0;
  margin-top: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "squadblack";
  src: url("../../fonts/squad_black-webfont.woff2") format("woff2"),
    url("../../fonts/squad_black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "squadheavy";
  src: url("../../fonts/squad-heavy-webfont.woff2") format("woff2"),
    url("../../fonts/squad-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "squadblack_italic";
  src: url("../../fonts/squad_black_italic-webfont.woff2") format("woff2"),
    url("../../fonts/squad_black_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$squad-black-italic: "squadblack_italic", sans-serif;
$squad-black: "squadblack", sans-serif;
$squad-heavy: "squadheavy", sans-serif;
$bold: 700;
$semi-bold: 600;

@mixin squad-black {
  font-family: $squad-black;
}

@mixin squad-heavy {
  font-family: $squad-heavy;
}

@mixin display1 {
  font-family: $squad-heavy;
  font-size: Rem(35px);
  line-height: Rem(40px);

  @include respond-to(tablet-up) {
    font-size: 3.5vw;
    line-height: 1;
  }
}

@mixin display2 {
  @include basekick(-12px, -4.5px);
  display: inline-block;
  font-family: $squad-black;
  font-size: Rem(35px);
  line-height: Rem(59px);

  @include respond-to(tablet-up) {
    @include basekick(-24px, -25.5px);
    font-size: Rem(50px);
    line-height: Rem(79px);
  }
}

@mixin display3 {
  font-family: $squad-black;
  font-size: Rem(40px);
}

@mixin caption1 {
  @include basekick(-2px, -4.5px);
  font-size: Rem(11px);
  font-weight: $bold;
  letter-spacing: Rem(2.3px);
  line-height: Rem(14px);
  text-transform: uppercase;
}
.display1 {
  @include display1;
}

.display2 {
  @include display2;
}

.display3 {
  @include display3;
}

.display4 {
  @include basekick(-2px, -4.5px);
  font-family: $squad-black;
  font-size: Rem(12px);
}

.display5 {
  font-family: $squad-black;
  font-size: Rem(25px);
  @include respond-to(tablet-up) {
    @include display2;
    line-height: Rem(100px) !important;
  }
}

.display6 {
  font-family: $squad-black;
  font-size: 8vw;
  line-height: 1;
  @include respond-to(desktop-up) {
    font-size: 2vw;
  }
}

.display7 {
  font-family: $squad-black;
  font-size: 3vw;
  line-height: 1;
  @include respond-to(desktop-up) {
    font-size: 1vw;
  }
}

.display-italic {
  font-family: $squad-black-italic;
}

.caption1 {
  @include caption1;
}

.body1 {
  font-family: "Open Sans", sans-serif;
  @include basekick(-6px, -7.5px);
  font-size: Rem(12px);
  line-height: Rem(22px);
  @include respond-to(tablet-up) {
    @include basekick(-9px, -10.5px);
    font-size: Rem(15px);
    line-height: Rem(28px);
  }
}
.body2 {
  font-family: "Open Sans", sans-serif;
  @include basekick(-6px, -7.5px);
  font-size: Rem(12px);
  line-height: Rem(18px);
  margin-bottom: 20px;
  @include respond-to(tablet-up) {
    @include basekick(-9px, -10.5px);
    font-size: Rem(15px);
    line-height: Rem(32px);
  }
}

.typograpgy__primary-btn {
  @include basekick(-5px, -6px);
  font-family: "Hind", sans-serif;
  font-size: Rem(12px);
  font-weight: $semi-bold;
  letter-spacing: Rem(1px);
  @include respond-to(tablet-up) {
    @include basekick(-9px, -10.5px);
    font-size: Rem(14px);
    letter-spacing: Rem(2px);
  }
}

.display8 {
  @include basekick(-12px, -4.5px);
  display: inline-block;
  font-family: $squad-black;
  font-size: Rem(18px);
  line-height: Rem(22px);

  @include respond-to(desktop-up) {
    @include basekick(-24px, -25.5px);
    font-size: Rem(50px);
    line-height: Rem(55px);
  }
}
