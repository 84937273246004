@keyframes shake {
  0% {
    transform: scale(1.1) rotate(12deg);
  }
  10% {
    transform: scale(1.1) rotate(0deg);
  }
  20% {
    transform: scale(1.1) rotate(6deg);
  }
  30% {
    transform: scale(1.1) rotate(0deg);
  }
  40% {
    transform: scale(1.1) rotate(3deg);
  }
  50% {
    transform: scale(1.1) rotate(0deg);
  }
  60% {
    transform: scale(1.1) rotate(1deg);
  }
  100% {
    transform: scale(1.1) rotate(0deg);
  }
}
